import _esArray from "../../modules/es.array.iterator";
import _esObject from "../../modules/es.object.to-string";
import _es from "../../modules/es.weak-map";
import _path from "../../internals/path";
var exports = {};
_esArray;
_esObject;
_es;
var path = _path;
exports = path.WeakMap;
export default exports;